navMenu();
function navMenu(){
  let scroll = 0;
  $('.o-gnav__menu').on('click',function() {
    if ( $(this).hasClass('-active') ) {
      // close
      $(this).removeClass('-active');
      $('.o-nav-menu').addClass('-close').removeClass('-open');
      $('body').addClass('-scroll').removeClass('-fixed');
      $(window).scrollTop(scroll);
    } else {
      // open
      scroll = $(window).scrollTop();
      $(this).addClass('-active');
      $('.o-nav-menu').addClass('-open').removeClass('-close');
      $('body').addClass('-fixed').removeClass('-scroll');
      $('body').css({
        'top': -1*scroll,
      });
    }
  });

  $('.o-nav-menu ul li a').on('click',function() {
    $('.o-gnav__menu').removeClass('-active');
    $('.o-nav-menu').removeClass('-open');
    $('body').css('position','static');
  });
}