anchorLinks();
function anchorLinks(){
  const speed = 400;
  if (windowWidth > windowSm) {
    var adjust = -90
  } else {
    var adjust = -60;
  }
  $('a[href^="#"]').click(function() {
    const href= $(this).attr("href");
    const target = $(href == "#" || href == "" ? 'html' : href);
    const position = target.offset().top + adjust;
    $('body,html').animate({scrollTop:position}, speed, 'swing');
    return false;
  });

  $(function() {
    var topBtn = $('.o-nav-scroll');    
    topBtn.hide();
    $(window).scroll(function () {
      if ($(this).scrollTop() > 100) {
        topBtn.fadeIn();
      } else {
        topBtn.fadeOut();
      }
    });
  });
}