// top page js

topFunction();

function topFunction(){
  if (!document.body.classList.contains('home')) return;
  sample();
}

function sample(){
}