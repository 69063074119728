iconSvg();

function iconSvg(){

  const items = document.getElementsByClassName('iconSvg');
  const iconArray = {
    'home': '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 486.196 486.196"><path d="M481.708 220.456l-228.8-204.6c-.4-.4-.8-.7-1.3-1-5-4.8-13-5-18.3-.3l-228.8 204.6c-5.6 5-6 13.5-1.1 19.1 2.7 3 6.4 4.5 10.1 4.5 3.2 0 6.4-1.1 9-3.4l41.2-36.9v238.6c0 18.7 15.2 34 34 34H169.908c17.6 0 31.9-14.3 31.9-31.9v-121.3c0-2.7 2.2-4.9 4.9-4.9h72.9c2.7 0 4.9 2.2 4.9 4.9v121.3c0 17.6 14.3 31.9 31.9 31.9h72.2c19 0 34-18.7 34-42.6v-228.7l41.2 36.9c2.6 2.3 5.8 3.4 9 3.4 3.7 0 7.4-1.5 10.1-4.5 4.8-5.6 4.3-14.1-1.2-19.1zm-86.2 66.7v145.1c0 9.7-4.8 15.6-7 15.6h-72.2c-2.7 0-4.9-2.2-4.9-4.9v-121.1c0-17.6-14.3-31.9-31.9-31.9h-72.9c-17.6 0-31.9 14.3-31.9 31.9v121.3c0 2.7-2.2 4.9-4.9 4.9H97.608c-3.8 0-7-3.1-7-7v-262.8l151.8-135.6 153.1 136.9v107.6z"/></svg>',
    'brackets': '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 6 13"><path fill-rule="evenodd" d="M5.869 6.5l.126.207-.3.077-3.802 6.212-1.888-.484L3.684 6.5.005.488 1.893.004l3.802 6.212.3.077-.126.207z"/></svg>',
    'arrow': '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 7"><path fill-rule="evenodd" fill="#FFF" d="M40 6.362l.015.012-.015.013V7h-.701l-.014.013L39.27 7H0V6h38.128L31.986.626l.729-.638L39.587 6H40v.362z"/></svg>',
    'arrowRev': '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 7"><path fill-rule="evenodd" fill="#FFF" d="M40 7H.73l-.015.013L.701 7H0v-.613l-.015-.013L0 6.361V6h.413L7.285-.013l.73.639L1.873 6H40v1z"/></svg>',
  };

  for (let i = 0; i < items.length; i++) {
    const item = items[i];
    const name = item.getAttribute('data-icon');
    if (name) {
      const icon = iconArray[name];
      item.innerHTML = icon;
    }
  }
}
