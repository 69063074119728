// common js

const windowSm = 750;
let windowWidth = window.innerWidth;

// if (windowWidth > windowSm) {
//   console.log(pc);
// } else {
//   console.log(sp);
// }

commonFunction();

function commonFunction(){
  dummy();
}


function dummy(){
}