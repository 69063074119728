$(window).on('load', function() {
  var scrollbar_width = window.innerWidth - document.body.scrollWidth;

  // 固定スクロールバーのとき（オーバーレイでない）
  if (scrollbar_width > 0) {
    $('.o-form-contact__privacy__wrap').addClass('is-scrollbar');
    
    // スクロールバーの幅が17pxでないとき
    if (scrollbar_width !== 17) {
      $('.o-form-contact__privacy__wrap').css('margin-right', '-' + scrollbar_width + 'px');
    }
  }

  var scrollable_height = $('.c-scrollbar').height() - 40;
  var adjustment_height = $('.o-form-contact__privacy__text .c-text-desc').outerHeight();
  var scrollbar_height = parseInt(scrollable_height * scrollable_height / adjustment_height);

  $('.c-scrollbar__thumb').css('height', scrollbar_height);

  var scrollbar_track = scrollable_height - scrollbar_height;

  $('.o-form-contact__privacy__wrap').on('scroll', function() {
    var offset = $(this).scrollTop() * scrollbar_track / (adjustment_height - scrollable_height);

    $('.c-scrollbar__thumb').css('transform', 'translateY(' + offset + 'px)');
  });

  var active = false, // つまみを操作しているかどうか
  scrollbar_thumb_cursor_y; // つまみ内のクリック位置

  $('.c-scrollbar__thumb').on('mousedown', function(event) {
    active = true;
    scrollbar_thumb_cursor_y = event.pageY - $(this).offset().top;
  });

  $(document).on('mouseup', function() {
    active = false;
  });

  $(document).on('mousemove', function(event) {
  if (!active) return;

  var scrollbar_thumb_y = ((event.pageY - $('.c-scrollbar').offset().top) / scrollbar_track * scrollbar_track) - scrollbar_thumb_cursor_y;

  // つまみが上下の領域外を超えないようにする
  if (scrollbar_thumb_y < 0) {
    scrollbar_thumb_y = 0;
  } else if (scrollbar_thumb_y > scrollbar_track) {
    scrollbar_thumb_y = scrollbar_track -4;
  }

  // つまみの位置設定
  $('.c-scrollbar__thumb').css('transform', 'translateY(' + scrollbar_thumb_y + 'px)');

  // つまみの位置に応じてスクロールさせる
  $('.o-form-contact__privacy__wrap').scrollTop(($('.c-scrollbar__thumb').offset().top - $('.c-scrollbar').offset().top) / scrollbar_track * (adjustment_height - scrollable_height));
  });

  // つまみを操作中はテキスト選択できないようにする
  $(document).on('selectstart', function() {
    if (active) return false;
  });
});