$('.o-form-contact__item dd .p-postal-code').on('input', function(event) {
  setTimeout(function(){
    var region = $('.o-form-contact__item dd .p-region');
    var regionVal = region.val();
    if(regionVal == '') {
      region.css('color', '#ccc');
    } else {
      region.css('color', '#000');
    }
	},100);
});

$('.o-form-contact__item dd select').change(function() {
  $(this).css('color', '#000');
});